export class LoginUserModel {
    public userName: string;
    public password: string;
    public appSelected: string;
    public sfOrgMembershipSID: string;
    public sfUserSID: string;
    public sfOrgSID: string;
    public dataTrack: string;
    public orgName: string;
    public rememberMe: boolean;
    public subOrgs: any;
    public isPrimOrgParent: boolean;
  
    constructor() {
        this.userName = '';
        this.sfOrgMembershipSID = '';
        this.dataTrack = '';
        this.orgName = '';
        this.password = null;
        this.rememberMe = false;
        this.isPrimOrgParent = false;
    }
  }