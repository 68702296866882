import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class routeGuard implements CanActivate {
  constructor(
        // declare variables
  ) {}

  canActivate() {
     return false;
  }
}