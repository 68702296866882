import { Injectable } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";

import * as storeConfig from "../cpxStateManager";

@Injectable({
  providedIn: "root"
})
export class CPXStoreService {
  constructor(public store: Store<any>) {}

  getReducer(reducerName: string): Observable<any> {
    return this.store.pipe(select(reducerName));
  }

  clearStore(data: { type: string }) {
    switch (data.type) {
      case storeConfig.CLEAR:
        this.store.dispatch({
          type: data.type,
          payload: {}
        });
        return true;
        break;
      default:
        return false;
        break;
    }
  }

  dispatchToStore(actionName: string, payload: any) {
    switch (actionName) {
      case storeConfig.ServiceList:
      case storeConfig.AppPrivileges:
      case storeConfig.LoggedInUser:
      case storeConfig.AppSecurityRoleList:
      case storeConfig.SelectedModule:
      case storeConfig.PasswordResetRequest:
      case storeConfig.ReportSelected:
      case storeConfig.DocumentInfo:
         this.store.dispatch({
          type: actionName,
          payload: payload
        });
        return true;
        break;
      default:
        return false;
        break;
    }
  }


 

  getPersonDataReducer(){
    return this.getReducer('PersonDataReducer');
  }
  getServiceListReducer(){
    return this.getReducer('ServiceListReducer');
  }




  dispatchAppSecurityRoleList(appSecurityRoleListData: any[]) {
    this.dispatchToStore(storeConfig.AppSecurityRoleList, {
      id: 1,
      appSecurityRoleList: appSecurityRoleListData,
      completed: true
    });
  }

  // Service List Dropdown
  dispatchServiceList(serviceListData: any[]) {
    const storePayload = {};
    storePayload[storeConfig.ServiceList] = serviceListData;
    this.dispatchToStore(storeConfig.ServiceList, storePayload);
  }

  // person by AD Username
  dispatchPersonData(personData: any[]) {
    const storePayload = {
      id: 1,
      completed: true,
      personData: personData
    };

    this.dispatchToStore(storeConfig.LoggedInUser, storePayload);
  }
  // report by Report name
  dispatchReportSlected(reportSelected: any[]) {
    const storePayload = {
      id: 1,
      completed: true,
      reportSelected: reportSelected
    };

    this.dispatchToStore(storeConfig.ReportSelected, storePayload);
  }

}
