import { jqxDataTableComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdatatable';
import { jqxWindowComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxwindow';
import { jqxChartComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxchart';
import { jqxGridComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxgrid';
import { jqxPopoverComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxpopover';
import { jqxButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxbuttons';
import { jqxInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxinput';
import { jqxDropDownListComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdropdownlist';
import { jqxTreeGridComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtreegrid';
import { jqxMenuComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxmenu';
import { jqxCheckBoxComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxcheckbox';
import { jqxRadioButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxradiobutton';
import { jqxDateTimeInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdatetimeinput';
import { jqxButtonGroupComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxbuttongroup';
import { jqxTreeComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtree';
import { jqxExpanderComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxexpander';
import { jqxTextAreaComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtextarea';
import { jqxComboBoxComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxcombobox';
import { jqxNumberInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxnumberinput';

import { jqxBarGaugeComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxbargauge';
import { jqxBulletChartComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxbulletchart';
import { jqxCalendarComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxcalendar';
import { jqxColorPickerComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxcolorpicker';
import { jqxComplexInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxcomplexinput';
import { jqxDockingComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdocking';
import { jqxDockingLayoutComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdockinglayout';
import { jqxDockPanelComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdockpanel';
import { jqxDragDropComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdragdrop';
import { jqxDrawComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdraw';
import { jqxDropDownButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxdropdownbutton';
import { jqxEditorComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxeditor';
import { jqxFileUploadComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxfileupload';
import { jqxFormattedInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxformattedinput';
import { jqxFormComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxform';
import { jqxGaugeComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxgauge';
import { jqxKanbanComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxkanban';
import { jqxKnobComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxknob';
import { jqxLayoutComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxlayout';
import { jqxLinearGaugeComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxlineargauge';
import { jqxLinkButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxlinkbutton';
import { jqxListBoxComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxlistbox';
import { jqxListMenuComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxlistmenu';
import { jqxLoaderComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxloader';
import { jqxMaskedInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxmaskedinput';
import { jqxNavBarComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxnavbar';
import { jqxNavigationBarComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxnavigationbar';
import { jqxNotificationComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxnotification';
import { jqxPanelComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxpanel';
import { jqxPasswordInputComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxpasswordinput';
import { jqxPivotDesignerComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxpivotdesigner';
import { jqxPivotGridComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxpivotgrid';
import { jqxRangeSelectorComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxrangeselector';
import { jqxRatingComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxrating';
import { jqxRepeatButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxrepeatbutton';
import { jqxResponsivePanelComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxresponsivepanel';
import { jqxRibbonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxribbon';
import { jqxSchedulerComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxscheduler';
import { jqxScrollBarComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxscrollbar';
import { jqxScrollViewComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxscrollview';
import { jqxSliderComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxslider';
import { jqxSortableComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxsortable';
import { jqxSplitterComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxsplitter';
import { jqxSwitchButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxswitchbutton';
import { jqxTabsComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtabs';
import { jqxTagCloudComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtagcloud';
import { jqxToggleButtonComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtogglebutton';
import { jqxToolBarComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtoolbar';
import { jqxTooltipComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtooltip';
import { jqxTreeMapComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxtreemap';
import { jqxValidatorComponent } from '../../../assets/jqx/jqwidgets-ts/angular_jqxvalidator';
import {
  jqxProgressBarComponent
  } from '../../../assets/jqx/jqwidgets-ts/angular_jqxprogressbar';
export {
  jqxDataTableComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdatatable';
export {
  jqxWindowComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxwindow';
export {
  jqxChartComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxchart';
export {
  jqxGridComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxgrid';
export {
  jqxPopoverComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxpopover';
export {
  jqxButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxbuttons';
export {
  jqxInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxinput';
export {
  jqxDropDownListComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdropdownlist';
export {
  jqxTreeGridComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtreegrid';
export {
  jqxMenuComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxmenu';
export {
  jqxCheckBoxComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxcheckbox';
export {
  jqxRadioButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxradiobutton';
export {
  jqxDateTimeInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdatetimeinput';
export {
  jqxButtonGroupComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxbuttongroup';
export {
  jqxTreeComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtree';

export {
  jqxExpanderComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxexpander';
export {
  jqxTextAreaComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtextarea';
export {
  jqxComboBoxComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxcombobox';

export {
  jqxBarGaugeComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxbargauge';
export {
  jqxBulletChartComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxbulletchart';
export {
  jqxCalendarComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxcalendar';
export {
  jqxColorPickerComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxcolorpicker';
export {
  jqxComplexInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxcomplexinput';
export {
  jqxDockingComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdocking';
export {
  jqxDockingLayoutComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdockinglayout';
export {
  jqxDockPanelComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdockpanel';
export {
  jqxDragDropComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdragdrop';
export {
  jqxDrawComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdraw';
export {
  jqxDropDownButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxdropdownbutton';
export {
  jqxEditorComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxeditor';
export {
  jqxFileUploadComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxfileupload';
export {
  jqxFormattedInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxformattedinput';
export {
  jqxGaugeComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxgauge';
export {
  jqxKanbanComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxkanban';
export {
  jqxKnobComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxknob';
export {
  jqxLayoutComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxlayout';
export {
  jqxLinearGaugeComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxlineargauge';
export {
  jqxLinkButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxlinkbutton';
export {
  jqxListBoxComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxlistbox';
export {
  jqxListMenuComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxlistmenu';
export {
  jqxLoaderComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxloader';
export {
  jqxMaskedInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxmaskedinput';
export {
  jqxNavBarComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxnavbar';
export {
  jqxNavigationBarComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxnavigationbar';
export {
  jqxNotificationComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxnotification';
export {
  jqxPanelComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxpanel';
export {
  jqxPasswordInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxpasswordinput';
export {
  jqxPivotDesignerComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxpivotdesigner';
export {
  jqxPivotGridComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxpivotgrid';
export {
  jqxRangeSelectorComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxrangeselector';
export {
  jqxRatingComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxrating';
export {
  jqxRepeatButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxrepeatbutton';
export {
  jqxResponsivePanelComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxresponsivepanel';
export {
  jqxRibbonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxribbon';
export {
  jqxSchedulerComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxscheduler';
export {
  jqxScrollBarComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxscrollbar';
export {
  jqxScrollViewComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxscrollview';
export {
  jqxSliderComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxslider';
export {
  jqxSortableComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxsortable';
export {
  jqxSplitterComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxsplitter';
export {
  jqxSwitchButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxswitchbutton';
export {
  jqxTabsComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtabs';
export {
  jqxTagCloudComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtagcloud';
export {
  jqxToggleButtonComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtogglebutton';
export {
  jqxToolBarComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtoolbar';
export {
  jqxTooltipComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtooltip';
export {
  jqxTreeMapComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxtreemap';
export {
  jqxValidatorComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxvalidator';
export {
jqxProgressBarComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxprogressbar';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
export {
  jqxNumberInputComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxnumberinput';

export {
  jqxFormComponent
} from '../../../assets/jqx/jqwidgets-ts/angular_jqxForm';



@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    jqxDataTableComponent,
    jqxWindowComponent,
    jqxChartComponent,
    jqxGridComponent,
    jqxPopoverComponent,
    jqxButtonComponent,
    jqxInputComponent,
    jqxDropDownListComponent,
    jqxTreeGridComponent,
    jqxMenuComponent,
    jqxCheckBoxComponent,
    jqxDateTimeInputComponent,
    jqxButtonGroupComponent,
    jqxRadioButtonComponent,
    jqxTreeComponent,
    jqxExpanderComponent,
    jqxTextAreaComponent,
    jqxComboBoxComponent,
    jqxNumberInputComponent,
    jqxBarGaugeComponent,
    jqxBulletChartComponent,
    jqxCalendarComponent,
    jqxColorPickerComponent,
    jqxComplexInputComponent,
    jqxDockingComponent,
    jqxDockingLayoutComponent,
    jqxDockPanelComponent,
    jqxDragDropComponent,
    jqxDrawComponent,
    jqxDropDownButtonComponent,
    jqxEditorComponent,
    jqxFileUploadComponent,
    jqxFormattedInputComponent,
    jqxGaugeComponent,
    jqxKanbanComponent,
    jqxKnobComponent,
    jqxLayoutComponent,
    jqxLinearGaugeComponent,
    jqxLinkButtonComponent,
    jqxListBoxComponent,
    jqxListMenuComponent,
    jqxLoaderComponent,
    jqxMaskedInputComponent,
    jqxNavBarComponent,
    jqxNavigationBarComponent,
    jqxNotificationComponent,
    jqxPanelComponent,
    jqxPasswordInputComponent,
    jqxPivotDesignerComponent,
    jqxPivotGridComponent,
    jqxRangeSelectorComponent,
    jqxRatingComponent,
    jqxRepeatButtonComponent,
    jqxResponsivePanelComponent,
    jqxRibbonComponent,
    jqxSchedulerComponent,
    jqxScrollBarComponent,
    jqxScrollViewComponent,
    jqxSliderComponent,
    jqxSortableComponent,
    jqxSplitterComponent,
    jqxSwitchButtonComponent,
    jqxTabsComponent,
    jqxTagCloudComponent,
    jqxToggleButtonComponent,
    jqxToolBarComponent,
    jqxTooltipComponent,
    jqxTreeMapComponent,
    jqxValidatorComponent,
    jqxProgressBarComponent,
    jqxFormComponent
  ],
  exports: [
    jqxDataTableComponent,
    jqxWindowComponent,
    jqxChartComponent,
    jqxGridComponent,
    jqxPopoverComponent,
    jqxButtonComponent,
    jqxInputComponent,
    jqxDropDownListComponent,
    jqxTreeGridComponent,
    jqxMenuComponent,
    jqxCheckBoxComponent,
    jqxDateTimeInputComponent,
    jqxButtonGroupComponent,
    jqxRadioButtonComponent,
    jqxTreeComponent,
    jqxExpanderComponent,
    jqxTextAreaComponent,
    jqxComboBoxComponent,
    jqxNumberInputComponent,
    jqxBarGaugeComponent,
    jqxBulletChartComponent,
    jqxCalendarComponent,
    jqxColorPickerComponent,
    jqxComplexInputComponent,
    jqxDockingComponent,
    jqxDockingLayoutComponent,
    jqxDockPanelComponent,
    jqxDragDropComponent,
    jqxDrawComponent,
    jqxDropDownButtonComponent,
    jqxEditorComponent,
    jqxFileUploadComponent,
    jqxFormattedInputComponent,
    jqxGaugeComponent,
    jqxKanbanComponent,
    jqxKnobComponent,
    jqxLayoutComponent,
    jqxLinearGaugeComponent,
    jqxLinkButtonComponent,
    jqxListBoxComponent,
    jqxListMenuComponent,
    jqxLoaderComponent,
    jqxMaskedInputComponent,
    jqxNavBarComponent,
    jqxNavigationBarComponent,
    jqxNotificationComponent,
    jqxPanelComponent,
    jqxPasswordInputComponent,
    jqxPivotDesignerComponent,
    jqxPivotGridComponent,
    jqxRangeSelectorComponent,
    jqxRatingComponent,
    jqxRepeatButtonComponent,
    jqxResponsivePanelComponent,
    jqxRibbonComponent,
    jqxSchedulerComponent,
    jqxScrollBarComponent,
    jqxScrollViewComponent,
    jqxSliderComponent,
    jqxSortableComponent,
    jqxSplitterComponent,
    jqxSwitchButtonComponent,
    jqxTabsComponent,
    jqxTagCloudComponent,
    jqxToggleButtonComponent,
    jqxToolBarComponent,
    jqxTooltipComponent,
    jqxTreeMapComponent,
    jqxValidatorComponent,
    jqxProgressBarComponent,
    jqxFormComponent
  ]
})
export class JqWidgetCommonModule { }
