import { NgModule,enableProdMode,Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { AppComponent } from './app.component';
import { SheetJSComponent } from './sheetViewModel/sheet.component';
import { LoginComponent } from './loginModule/login.component';
import { StoreModule } from '@ngrx/store';
import { UserIdleModule } from 'angular-user-idle';
import { routeGuard } from './routeGuard/routeGuard'
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


import { JqWidgetCommonModule } from './global/module/jqwidgetCommon.module';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { LoggedInUserReducer,AppPrivilegesReducer, ReportSelectedReducer, PasswordChangeReducer, SearchInfoReducer,SearchViewInfoReducer } from './cpxStateManager';

const appRoutes: Routes = [
  { path: 'adc', component: LoginComponent, children: [ 
  { path: 'sheetView', component: SheetJSComponent }]},
  { path: '',redirectTo: 'adc',pathMatch: 'prefix'},
  { path: 'assets',redirectTo: 'adc',pathMatch: 'prefix'},  
  { path: 'assets', redirectTo: 'adc', canActivate:[routeGuard] },
  { path: '**', component: LoginComponent }
];



@NgModule({
  declarations: [
    AppComponent, 
    LoginComponent,
    SheetJSComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes,{ enableTracing: true , onSameUrlNavigation: 'reload'}),// <-- debugging purposes only
    StoreModule.forRoot({ LoggedInUserReducer: LoggedInUserReducer, AppPrivilegesReducer: AppPrivilegesReducer, ReportSelectedReducer: ReportSelectedReducer,PasswordChangeReducer: PasswordChangeReducer,SearchInfoReducer: SearchInfoReducer,SearchViewInfoReducer: SearchViewInfoReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 50 }),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) and `ping` is 120 (2 minutes).
    UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 120}),
    BrowserModule,
    //HttpModule,
    HttpClientModule,
    JqWidgetCommonModule,
    //NgxExtendedPdfViewerModule,
    FormsModule,
    WjGridModule
  ],
  providers: [],
  exports: [AppComponent, LoginComponent, SheetJSComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }