import '@grapecity/wijmo.styles/wijmo.css';
import '../bootstrap.css';

import { Component, OnInit,AfterViewInit,OnDestroy, ViewChild, ChangeDetectionStrategy  } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { appService } from '../app.service';
import {enableProdMode} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { Subscription,Observable } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { CryptoJS } from 'node-cryptojs-aes'

import { Store } from '@ngrx/store';
import { LoggedInUser, ReportSelected, DocumentInfo, CLEAR } from '../cpxStateManager';
import { LoginUserModel } from '../models/cpxLoginUserModel'
import { RizeDataModel } from '../models/rizeDataModel'
import { ReportSelectedModel } from '../models/cpxReportSelectedModel'
import { DocumentInfoModel } from '../models/pcsDocumentInfoModel'
import { CPXStoreService } from '../services/cpxstore.service'
import { NavigationEnd } from '@angular/router'


import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';

enableProdMode();
//import { fs } from 'file-system';
//import { PNG } from 'pngjs3';
//import { sync as PNGSync } from 'pngjs3';


import {

  jqxInputComponent,
  jqxPasswordInputComponent,
  jqxValidatorComponent,
  jqxNavBarComponent,
  jqxButtonComponent,
} from '../global/module/jqwidgetCommon.module';


declare var tableau: any;
class DataItem {
  id: number;
  country: string;
  active: boolean;
  downloads: number;
  sales: number;
  expenses: number;
}
@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [appService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('userNameInput') userNameInput: jqxInputComponent;
  @ViewChild('passwordInput') passwordInput: jqxPasswordInputComponent;
  @ViewChild('credentialsValidator') credentialsValidator: jqxValidatorComponent;
  @ViewChild('navBar') navBar:jqxNavBarComponent;
  @ViewChild('loginButton') loginButton: jqxButtonComponent;
  
  count$: Observable<number>;
  public userLogin: LoginUserModel;
  public sfUserSID;
  public sfOrgSID;
  public rizeDataArray = new Array();
  public rizeDataStruct: RizeDataModel;
  public selectedReport: ReportSelectedModel;
  public documentInfo: DocumentInfoModel;
  //@HostListener('window:resize', ['$event'])
  /*Menu selection*/
  public adcSelected = false;
  public fosSelected = false;
  public showHome = true;
  public login = true;
  public showDataSearch = false;
  public tableauReport = false;
  public menu = false;
  public fosMenu = false;
  public search = false;
  public searchPanel = false;  
  public searchViews = false;  
  public searchEmpty = false;
  public searchFlat = false;
  public searchFlatView = false;
  public dataConnectionsView = false;   
  public invalidLogin = false;
  public fgpwdEmailSent = false;
  public controlTable;
  public showLogin = 'none';
  public showPWDChange = 'node'
  public showContactUs = 'none';
  public showAssitance = 'none';
  public showFGPWD = 'none';
  public isAdmin = false;
  public passWordChange = false;
  public loginInfo ='';
  public loginInfo1 ='';
  public loginInfo2 ='';
  public loginInfo3 ='';
  public forgotpwdemailinfo ='';
  public passwordMessage = '';
  /*button enable*/
  public searchEnabled = true;
  
  /*window heights*/
  public windowInnerHeight;
  public contentWindowHeight;
  public headerWindowHeight;
  public menuWindowHeight;
  public footerWindowHeight;
  public popupWindowHeight; 
  public popupWindowWidth;
  public popupWindowHeightOffset; 
  public popupWindowWidthOffset;
  public popupWindowTableHeight;       

  /*Tableau Viz*/
  public tableauViz;

  /*scroll view dimensions*/
  public scrollHeight;
  public scrollWidth;

  /*Credentials*/
  public userName="";
  public password="";
  public userNameDisplay="";
  public appAcces=[];

  /*Workbooks*/
  public workbooksJSONSource;
  public dataAdapter;
  public dataSource;

  /*Workbook views*/
  public workbookViewsJSONSource;
  public viewsDataAdapter;
  public viewsDataSource;

  /*Security token & global variables */
  public secToken ="";
  public siteID="";
  public workBookID="";
  public secTicket ="";
  public passwordChangeUser = '';
  /*Temp image storage */
  public imageToShow;
  public isImageLoading=true;

  /*Search functions*/
  public workBookAttributes=[];

  /*Date values*/
  public createDate = null;
  public updateDate = null;

  /*Data source connections*/
  public workBookDataConnections=[];
  public siteDataSources=[];
  public dataSourceConnections;
  public workBookDataConnectionDataAdapter;
  public workBookDataConnectionSource;
  public menuSource=[];

  /*Presentations*/
  /*public pdfSRC = '../assets/pdf/CRCnarrativeforonlineformat.pdf'*/

  
  /*Title*/
  title = 'AMGA data collection';
  /*Display sections*/

  public siteSource=[];
  public projectSource=[];
  public surveySource=[];
  public uploadedFiles: Array < File > ;

  /*Tool Tip */
  public crDateCompTooltip = "<b>Note:</b> <i>Select comparative operater to enable date selection</i>";
  public upDateCompTooltip = "<b>Note:</b> <i>Select comparative operater to enable date selection</i>";

  appPrvilegeObservable: Observable<any>;

  appPrvilegeSubscription: Subscription;
  routerSubscription: Subscription;

  passawordChangeObservable: Observable<any>;

  constructor(private appService: appService, private sanitizer: DomSanitizer,  private httpclient:HttpClient, private router:Router, private cpxStoreService: CPXStoreService,  private store: Store<any>,private route: ActivatedRoute,private userIdle: UserIdleService) {
      this.userLogin = new LoginUserModel();
      this.rizeDataStruct = new RizeDataModel();
      this.appPrvilegeObservable = this.cpxStoreService.getReducer('AppPrivilegesReducer'); 
    }
  ngOnInit(){
    this.windowInnerHeight = window.innerHeight;

    this.contentWindowHeight = this.windowInnerHeight - (this.windowInnerHeight*.24)+'px';
    this.headerWindowHeight = this.windowInnerHeight*.15+'px';
    this.menuWindowHeight = this.windowInnerHeight*.04+'px';
    this.footerWindowHeight = this.windowInnerHeight*.05+'px';
    this.scrollHeight = this.windowInnerHeight*.70;
    this.scrollWidth = window.innerWidth*.70;
    this.popupWindowHeight=this.windowInnerHeight*.36; 
    this.popupWindowWidth = window.innerWidth*.3;
    this.popupWindowHeightOffset=this.windowInnerHeight*.33;  
    this.popupWindowWidthOffset = window.innerWidth*.33; 
    this.popupWindowTableHeight = this.popupWindowHeight-100;
    this.appPrvilegeSubscription = this.appPrvilegeObservable.subscribe((data) => {
      if ( data && data.userName != '') {
          this.userName = data.userName;
          this.password = data.password;
          this.router.navigate(['/adc/sheetView']);
      }
    });
      //Start watching for user inactivity.
      this.userIdle.startWatching();
     
      // Start watching when user idle is starting.
     this.userIdle.onTimerStart().subscribe(count => console.log(count));
  
      // Start watch when time is up and logout
      this.userIdle.onTimeout().subscribe(() =>this.logout());

  }
  onResize($event) {
    this.windowInnerHeight = window.innerHeight;
    this.contentWindowHeight = this.windowInnerHeight - (this.windowInnerHeight*.24)+'px';
    this.headerWindowHeight = this.windowInnerHeight*.15+'px';
    this.menuWindowHeight = this.windowInnerHeight*.04+'px';
    this.footerWindowHeight = this.windowInnerHeight*.05+'px';
    this.scrollHeight = this.windowInnerHeight*.70;
    this.scrollWidth = window.innerWidth*.70;
    this.popupWindowHeight=this.windowInnerHeight*.3; 
    this.popupWindowWidth = window.innerWidth*.3;
    this.popupWindowHeightOffset=this.windowInnerHeight*.33;  
    this.popupWindowWidthOffset = window.innerWidth*.33; 
    this.popupWindowTableHeight = this.popupWindowHeight-100;
  }
  ngAfterViewInit(){
    var passInput = document.getElementById('passwordInput');
    passInput.addEventListener("keydown", (event) => {
      var keyCode = event.code;
      
      if(keyCode != undefined && keyCode.indexOf('Enter') != -1){
        this.performLogin();
      }
    });
  }
  ngOnDestroy(){
    if (this.appPrvilegeSubscription) {
      this.appPrvilegeSubscription.unsubscribe();
    }
  }
  setActiveMenu(selectedMenu){
    if(selectedMenu ==='dataSearch'){
        this.showHome = false;
        this.showDataSearch = true;
        this.tableauReport = false;
        this.search = false;
        this.searchPanel = false;
      }
    if(selectedMenu ==='home'){
      this.showDataSearch = false;
      this.showHome = true;
      this.tableauReport = false;
      this.search = false;
      this.searchPanel = false;
    }
    if(selectedMenu === 'profile'){
      this.showDataSearch = false;
      this.showHome = false;
      this.tableauReport = true; 
      this.search = false;
      this.searchPanel = false;
  }
    if(selectedMenu === 'search'){
      this.showDataSearch = false;
      this.showHome = false;
      this.tableauReport = false;
      this.search = true;
      this.searchPanel = true;
    }

  }
padding: any = { left: 10, top: 10, right: 10, bottom: 10 };

titlePadding: any = { left: 0, top: 0, right: 0, bottom: 0 };

/*Makes and Array Unique */
onlyUnique(value, index, self) { 
  return self.indexOf(value) === index;
}

disableAllHelpDisplay(){
  //this.showLogin = 'none';
  this.showAssitance = 'none';
  this.showContactUs = 'none';
}

performLogin(){
  this.disableAllHelpDisplay();
  this.credentialsValidator.validate();
  this.userName = this.userNameInput.val();
  this.password = CryptoJS.AES.encrypt(this.passwordInput.val(),'CPX_PWD_Encrypt_1234_!@#$').toString();
  this.userLogin.userName = this.userName;
  this.userLogin.password = this.password;
  this.userLogin.appSelected = "dataCollect";
  if(this.userName !="" && this.userName!="User Name"){
    this.appService.getADLogin(this.userName,this.password).subscribe((adLogin) => {
      var responseMesg = adLogin['response_message'];
      var result = adLogin['response_body'];
      //alert(JSON.stringify(result));
      this.userLogin.dataTrack = result[0].track;
      this.userLogin.sfOrgMembershipSID = result[0].collMemID;
      this.userLogin.orgName = result[0].orgName;
      this.userLogin.sfUserSID = result[0].userID;
      this.userLogin.sfOrgSID = result[0].PrimeAffID;
      this.userLogin.isPrimOrgParent = result[0].isParent;
      this.userLogin.subOrgs = result[0].subOrgs.orgs;
      this.sfUserSID = result[0].userID;
      this.sfOrgSID = result[0].PrimeAffID;
      if( responseMesg != undefined && responseMesg == 'Authenticated'){
      //this.appService.getSecurityToken(this.userName,this.password,this.userLogin.appSelected).subscribe((securityToken) => {
        this.invalidLogin = false;
        this.fgpwdEmailSent = false;
        this.login = false;
        this.menu = true;
        this.showLogin = 'none';
        this.showPWDChange = 'none';
        this.showContactUs = 'none';
        
      /*Hamburger Menu Sub sections*/
         this.projectSource.push({"name":"","value":"AMGA Surveys"})

      var qryUserName = this.userName.indexOf('@') != -1? this.userName.substring(0,this.userName.indexOf('@')):this.userName;
      //this.appService.getUserGroupDetails(qryUserName).subscribe((userGrpDetails) => {
        this.userNameDisplay = this.userName//JSON.parse(JSON.stringify(userGrpDetails)).recordset[0].display_name;
        if(this.isAdmin){
        this.menuSource = [
          { html: "<span style='text-align:left; margin-left:1%;'>Logout</span>"
          },
          {
              html: "<span style='text-align:left;'>Admin</span>", items: [
                  { html: "<span>Reset My Password</span>"},
                  { html: "<span>About AMGA Data Portal</span>" }]
          },
          { html: "<style='text-align:left; margin-left:1%;'>User :" + this.userNameDisplay +"</span>" },
        ];
      } else {       
        this.menuSource = [
          { html: "<span style='text-align:left; margin-left:1%;'>Logout</span>"
          },
          {
              html: "<span style='text-align:left;'>Admin</span>", items: [
                  //{ html: "<span>View Admin Report</span>" },
                  //{ html: "<span>User Management</span>"},
                  { html: "<span>Reset My Password</span>"},
                  { html: "<span>About RIZE</span>" }]
          },
          { html: "<style='text-align:left; margin-left:1%;'>User :" + this.userNameDisplay +"</span>" },
          //{ html: "<style='text-align:left; margin-left:1%;'>Data Thru 2021</span>" }
        ];
        }
        this.dispatchAppPrivileges(this.userLogin);
      //})
    //})
  //})
  }
  else if(responseMesg != undefined && responseMesg == 'No subscription found'){
    this.invalidLogin = true;
    this.loginInfo='Username or password is invalid, or subscription has expired !'
    this.loginInfo1='Click “Forgot my password” to reset your credentials or'
    this.loginInfo2='Click “Request an Account” to purchase or update your subscription'
    this.loginInfo3='Or contact amgasurveyssupport@amga.org for help';
  }
  else{
        this.invalidLogin = true;
        this.loginInfo='Username or password is invalid, or subscription has expired'
        this.loginInfo1='Click “Forgot my password” to reset your credentials or'
        this.loginInfo2='Click “Request an Account” to purchase or update your subscription'
        this.loginInfo3='Or contact amgasurveyssupport@amga.org for help';
      }
    })
}
}
rightMenuclick(event: any): void {
  //this.navBar.minimized(true);
  if(event.args.innerText == 'Logout'){
    this.logout();
  }else if(event.args.innerText == 'View Admin Report'){
    //this.getAdminReport();
  }else if(event.args.innerText == 'User Management'){
    //this.getUserManagement();
  }else if(event.args.innerText == 'Reset My Password'){
    this.getResetMyPassword();
  }else if(event.args.innerText == 'About RIZE'){
    this.getAboutRIZE();
  }
};
logout(){
  var clearStore = {
    type: 'CLEAR',
    payload: {
    }}
  this.cpxStoreService.clearStore(clearStore);
  /*if(this.navBar){
	this.navBar.minimized(true);
	this.navBar.destroy();
  }*/
  this.login = true;
  this.menu = false;
  this.tableauReport = false;
  this.search = false;
  this.searchPanel = false;
  this.searchFlat = false;
  this.searchFlatView = false;
  this.router.navigate(['/']);
  this.resetSession();
  window.location.reload();
}
resetSession(){
  this.userLogin.userName = "";
  this.userLogin.password = "";
  this.invalidLogin = false;
  this.fgpwdEmailSent = false;
  this.userName="";
  this.password="";
  if(this.userName==''){
    this.login = true;
    this.menu = false;
    this.dispatchAppPrivileges(this.userLogin);
    this.router.navigateByUrl('/');
  }
}
credentialRules: any[] = [
  {
      input: "#userNameInput", message: "User name is required!", action: 'keyup', rule: (input: any, commit: any): boolean => {
          if(this.userNameInput.val() != "" && this.userNameInput.val() != "User Name"){
            this.loginButton.disabled(false);
          }else{
            this.loginButton.disabled(true);
          }
          return this.userNameInput.val() != "" && this.userNameInput.val() != "User Name";
      }
  },
  {
      input: "#passwordInput", message: "Password is required!", action: 'keyup', rule: (input: any, commit: any): boolean => {
        if(this.passwordInput.val() != "" && this.passwordInput.val() != "Password"){
          this.loginButton.disabled(false);
        }else{
          this.loginButton.disabled(true);
        }
          return this.passwordInput.val() != "" && this.passwordInput.val() != "Password";
      }
  }]
    dispatchAppPrivileges(userLoginData: any) {
      this.store.dispatch({
        type: LoggedInUser,
        payload: {
          id: 1,
          appPrivileges: userLoginData,
          completed: true
        }
      });
    }

     getPosition(string, subString, index) {
      return string.split(subString, index).join(subString).length;
   }


    getContactAMGASupport(){
      this.restartSessionTimeOut();
      var id='adcContactAMGASupport'
      this.router.navigate(['/adc/pdfReport',id]);
    }
    getAboutRIZE(){
      window.open("https://www.amga.org/rise-to-immunize/");    
    }
    getResetMyPassword(){
      window.open("https://my.amga.org/");    
    }
    initiateLogin(){
      this.showContactUs = 'none';
      this.showAssitance = 'none';
      this.showFGPWD = 'none';
      this.showPWDChange = 'none';
      this.showLogin = this.showLogin == 'none' ? 'inline-table':'none';
      if(this.showLogin == 'none')
      this.showAssitance = 'none';
    }
    showContactDetails(){
      this.showLogin = 'none';
      this.showPWDChange = 'none';
      this.showFGPWD = 'none';
      this.showAssitance = 'none';
      this.showContactUs = this.showContactUs == 'none' ? 'inline-table':'none';
    }
    showAssitanceDetails(){
      this.showContactUs = 'none';
      this.showAssitance = 'inline-table';
    }
    forgotPwd(){
      this.showContactUs = 'none';
      this.showAssitance = 'none';
      this.showLogin = 'none'
      this.showPWDChange = 'none';
      window.open("https://my.amga.org/");
      //this.router.navigateByUrl('/forgotPwd');
    }
	restartSessionTimeOut() {
   /* this.navBar.minimized(true);
    this.userIdle.stopWatching();
	  this.userIdle.startWatching();*/	
    }
    minimizeHBMenu(){
      //this.navBar.minimized(true);
    }


openFile(){
  //alert('hi...')
  document.querySelector('input').click()
}
hideColumns(){
  alert('hi...')
var grid = wjCore.Control.getControl("#gFlexGrid");
/*var columns = grid.col;
columns[1].visible = false;*/
}
handle(e){
  console.log('Change input file')
}
}
