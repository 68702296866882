import { Injectable } from '@angular/core';

//import { Http, Response, Request, Headers, RequestOptions, ResponseContentType  } from '@angular/http';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from "rxjs/operators";


@Injectable()
export class appService {

  constructor(private httpclient:HttpClient) { }
  public getDataSubmissionEmails() {
       return this.httpclient.get('/api/getDataSubmissionEmails');
  }
  public getRizeDataSubmissionEmails() {
    return this.httpclient.get('/api/getRizeDataSubmissionEmails');
  }
  public getRIZEDataForOrganization(SF_MEMBERSHIP_SID) {
    var body= { 
      'SF_MEMBERSHIP_SID': SF_MEMBERSHIP_SID
   }
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });   
    let options = {
      headers: httpHeaders
    }; 
    return this.httpclient.post('/api/getRIZEDataForOrganization',body,options);
}

  public getSecurityTicket(userName,appSelected) {
    var body= { 
      'userName': userName,
      'appSelected': appSelected,
    }
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });   
    let options = {
      headers: httpHeaders
    }; 

      return this.httpclient.post('/api/getSecurityTicket?rand='+Math.random().toString(),body,options);
  }
    public getSecurityToken(userName,passWord,appSelected) {
      //console.log('Get Security Token called' + userName);
     var body= { 
        'userName': userName,
        'passWord': passWord,
        'appSelected': appSelected,
     }
      let httpHeaders = new HttpHeaders({
        'Content-Type' : 'application/json'
      });   
      let options = {
        headers: httpHeaders
      }; 
      return this.httpclient.post('/api/getSecurityToken?rand='+Math.random().toString(),body,options);
  }
  public getUserSubscriptionYears(userName,appSelected) {
    //console.log('Get Security Token called' + userName);
   var body= { 
      'userEmail': userName,
      'appSelected':appSelected,
   }
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });   
    let options = {
      headers: httpHeaders
    }; 
    return this.httpclient.post('/api/getUserSubscriptionYears?rand='+Math.random().toString(),body,options);
}
  
  public getADLogin(userName,passWord) {
    //console.log('Get ADLogin called' + userName);
    if(userName.indexOf("@")==-1){
      userName = 'amga-va\\'+userName;
    }
   var body= { 
      'userName': userName,
      'passWord': passWord,
   }
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });   
    let options = {
      headers: httpHeaders
    }; 
    return this.httpclient.post('/api/getADLogin',body,options);
}
public sendPasswordResetEmail(requesterEmail,userName) {
  //console.log('Get sendPasswordResetEmail called' + userName);
 var body= { 
    'requesterEmail': requesterEmail,
    'userName':userName,
    }
  let httpHeaders = new HttpHeaders({
    'Content-Type' : 'application/json'
  });   
  let options = {
    headers: httpHeaders
  }; 
  return this.httpclient.post('/api/sendEmail',body,options);
}
public updateADUserPwd(userName,passWord) {
  //console.log('updateADUserPwd called' + userName);
 var body= { 
    'userName': userName,
    'passWord': passWord,
 }
  let httpHeaders = new HttpHeaders({
    'Content-Type' : 'application/json'
  });   
  let options = {
    headers: httpHeaders
  }; 
  return this.httpclient.post('/api/updateADUserPwd',body,options);
}
public verifyJWToken(token) {
  //console.log('verifyJWToken' + token);
 var body= { 
    'token': token
   }
  let httpHeaders = new HttpHeaders({
    'Content-Type' : 'application/json'
  });   
  let options = {
    headers: httpHeaders
  }; 
  return this.httpclient.post('/api/validateJWTToken',body,options);
}
public getUserRoleForSite(token,siteID,userID) {
    var body= { 
      'token': token,
      'siteID': siteID,
      'userID':userID
   }
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });   
    let options = {
      headers: httpHeaders
    }; 
    return this.httpclient.post('/api/getUserRoleForSite',body,options);
}
  public getSiteWorkbooks(token,siteID) {
    return this.httpclient.get('/api/getSiteWorkBooks?secToken='+token+'&siteID='+siteID);
}
public getAllParticipatingOrgs(){
  return this.httpclient.get('/api/getAllParticipatingOrgs');
}
public getSiteWorkbooksWithFilter(token,siteID,nameFilterString,tagFilterString,createDt,updateDt,createDtCompOp,updateDtCompOp) {
      //console.log('Get site workbooks with filter called');
      let params = new HttpParams().set('nameFilterString', nameFilterString);
      params=params.append('createDt',createDt);
      params=params.append('updateDt',updateDt);
      params=params.append('secToken',token);
      params=params.append('siteID',siteID);
      params=params.append('tagFilterString',tagFilterString);  
      params=params.append('createDtCompOp',createDtCompOp);
      params=params.append('updateDtCompOp',updateDtCompOp);       
      return this.httpclient.get('/api/getSiteWorkBooks',{params: params});
}
public getSiteWorkbookByID(token,siteID,workBookID) {
  //console.log('Get site workbook BY ID called');
  let params = new HttpParams().set('secToken', token);
  params=params.append('siteID',siteID);
  params=params.append('workBookID',workBookID);

       return this.httpclient.get('/api/getSiteWorkBookByID',{params: params});
}
public getSiteWorkbookViews(token,siteID,workBookID) {
	//console.log('Get Site Workbook Views called');
	return this.httpclient.get('/api/getSiteWorkBookViews?secToken='+token+'&siteID='+siteID+'&workBookID='+workBookID);
}
public getSiteViews(token,siteID) {
	//console.log('Get site views called');
    return this.httpclient.get('/api/getSiteViews?secToken='+token+'&siteID='+siteID);
}
public getSiteViewsWithFilter(token,siteID,nameFilterString,tagFilterString,createDate,updateDate,createDtCompOp,updateDtCompOp) {
  //console.log('Get site views with filter called');
  let params = new HttpParams().set('nameFilterString', nameFilterString);
  params=params.append('createDt',createDate);
  params=params.append('updateDt',updateDate);
  params=params.append('secToken',token);
  params=params.append('siteID',siteID);
  params=params.append('tagFilterString',tagFilterString); 
  params=params.append('createDtCompOp',createDtCompOp);
  params=params.append('updateDtCompOp',updateDtCompOp);    
  return this.httpclient.get('/api/getSiteViews',{params: params});
}
public getSiteWorkbookPreview(token,siteID,workBookID) {
  //console.log('Get site workbook preview called');
       return this.httpclient.get('/api/getSiteWorkBookImage?workBookID='+workBookID+'&siteID='+siteID+'&secToken='+token, { responseType: 'blob' });
}
public getSiteViewPreview(token,siteID,workBookID,viewID) {
  //console.log('Get site view preview called');
       return this.httpclient.get('/api/getSiteViewImage?workBookID='+workBookID+'&siteID='+siteID+'&secToken='+token+'&viewID='+viewID, { responseType: 'blob' });
}
public getSiteWorkbookConnections(token,siteID,workBookID) {
  //console.log('Get site workbook data source called');
  let params = new HttpParams().set('workBookID', workBookID);
  params=params.append('siteID',siteID);
  params=params.append('secToken',token);

       return this.httpclient.get('/api/getWorkBookConnections',{params: params});
}
public getDataSourceConnections(token,siteID,datasourceID) {
  //console.log('Get site data source connections called');
  let params = new HttpParams().set('datasourceID', datasourceID);
  params=params.append('siteID',siteID);
  params=params.append('secToken',token);

       return this.httpclient.get('/api/getDataSourceConnections',{params: params});
}
public getSiteDataSources(token,siteID) {
  //console.log('Get site data sources');
  let params = new HttpParams().set('siteID', siteID);
  params=params.append('secToken',token);

       return this.httpclient.get('/api/getSiteDatasources',{params: params});
}
public getDataSourceFile(token,siteID,datasourceID) {
  //console.log('Get site data source connections called');
  let params = new HttpParams().set('datasourceID', datasourceID);
  params=params.append('siteID',siteID);
  params=params.append('secToken',token);

  return this.httpclient.get('/api/getDataSourceFile',{params: params, responseType: 'blob' });
}
public getCPXUserByUserName(userName) {
  //console.log('Get getCPXUserByUserName called');
  let params = new HttpParams().set('userName', userName);
if(userName.indexOf("@")==-1){
  return this.httpclient.get('/api/getCPXUserByUserName?rand='+Math.random().toString(),{params: params});
}else{
  return this.httpclient.get('/api/getCPXUserByEmail?rand='+Math.random().toString(),{params: params});
}
}
public getReportControlTable(token){
  let params = new HttpParams().set('token', token);
  return this.httpclient.get('/api/getReportControlTable?rand='+Math.random().toString(),{params: params});
}
public getCPXControlDates(token){
  let params = new HttpParams().set('token', token);
  return this.httpclient.get('/api/getCPXControlDates?rand='+Math.random().toString(),{params: params});
}
public getCPXGitVersion(token){
  let params = new HttpParams().set('token', token);
  return this.httpclient.get('/api/getCPXGitVersion?rand='+Math.random().toString(),{params: params});
}
public getUserDetails(appSelected){
 let params = new HttpParams().set('appSelected', appSelected);
  return this.httpclient.get('/api/getUserDetails?rand='+Math.random().toString(),{params: params});
}
public getCPXGroupName(){
  return this.httpclient.get('/api/getCPXGroupName?rand='+Math.random().toString());
}
public getUserGroupDetails(userName){
  let params = new HttpParams().set('userName', userName);
  return this.httpclient.get('/api/getUserGroupDetails',{params: params});
}
public updateUserDetails(userName,origEmail,newEmail,userLevel,token){
  //console.log('Get Security Token called' + userName);
  var body= { 
     'userName': userName,
     'origEmail': origEmail,
     'newEmail': newEmail,
     'userLevel':userLevel,
     'token':token
  }
   let httpHeaders = new HttpHeaders({
     'Content-Type' : 'application/json'
   });   
   let options = {
     headers: httpHeaders
   }; 
   return this.httpclient.post('/api/updateUserDetails?rand='+Math.random().toString(),body,options);
}
public updateUserMemberGroupDetails(userName,userMemberGroups,token){
  var body= { 
     'userName': userName,
     'userMemberGroups': userMemberGroups,
     'token':token
  }
   let httpHeaders = new HttpHeaders({
     'Content-Type' : 'application/json'
   });   
   let options = {
     headers: httpHeaders
   }; 
   return this.httpclient.post('/api/updateUserMemberGroups?rand='+Math.random().toString(),body,options);
}
public getSalesForceUsers(userName, password,appSelected){
  var body= { 
     'userName': userName,
     'password': password,
     'appSelected': appSelected,
  }
   let httpHeaders = new HttpHeaders({
     'Content-Type' : 'application/json'
   });   
   let options = {
     headers: httpHeaders
   }; 
   return this.httpclient.post('/api/getSalesForceUsers?rand='+Math.random().toString(),body,options);
}

public getAppInstance(){
  return this.httpclient.get('/api/getAppInstance');
}
/*public getAppToken(userName,passWord){
  let params = new HttpParams().set('userName', userName);
  params=params.append('passWord',passWord);
  return this.httpclient.get('/api/getAppToken',{params: params});
}*/
public getAppToken(userName,passWord) {
  //console.log('Get ADLogin called' + userName);
  if(userName.indexOf("@")==-1){
    userName = 'amga-va\\'+userName;
  }
 var body= { 
    'userName': userName,
    'passWord': passWord,
 }
  let httpHeaders = new HttpHeaders({
    'Content-Type' : 'application/json'
  });   
  let options = {
    headers: httpHeaders
  }; 
  return this.httpclient.post('/api/getAppToken',body,options);
}
public getSurveyReport(token,fileName) {
  //console.log('Get site data source connections called');
  let params = new HttpParams().set('secToken', token);
  params=params.append('fileName',fileName);
  return this.httpclient.get('/api/downloadFile',{params: params, responseType: 'blob' });
}
extractData(res) {
const body = res.recordset;
return body || {};
}

toData(res: Response) {
const body = res;
return body || {};
}

tojsonData(res: Response) {
const body = res.json();
return body || {};
}

private handleError(error: any): Promise<any> {
console.error('An error occurred', error);
return Promise.reject(error.message || error);
}

handleErrorObservable(error: Response | any) {
console.error(error.message || error);
return Observable.throw(error.message || error);
}


}
