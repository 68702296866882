import '@grapecity/wijmo.styles/wijmo.css';
import '../bootstrap.css';


import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { HttpClient } from '@angular/common/http';

import * as XLSX from 'xlsx';
import { Subscription,Observable } from 'rxjs';

import { appService } from '../app.service';
import { CPXStoreService } from '../services/cpxstore.service'

import { WjGridModule, WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';

import { LoginUserModel } from '../models/cpxLoginUserModel'
import { RizeDataModel } from '../models/rizeDataModel'
import {
  jqxLoaderComponent,
  jqxDropDownListComponent
} from '../global/module/jqwidgetCommon.module';

type AOA = any[][];

class DataItem {
  id: number;
  country: string;
  active: boolean;
  downloads: number;
  sales: number;
  expenses: number;
}

@Component({
    selector: 'app-sheet',
   templateUrl: './sheet.component.html',
   providers: [appService],
})

export class SheetJSComponent implements OnInit, AfterViewInit {
  @ViewChild('dataGrid') dataGrid: WjFlexGrid;
  @ViewChild('orgList') orgList: jqxDropDownListComponent;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('jqxLoader') jqxLoader: jqxLoaderComponent;
  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  wjData: wjCore.CollectionView;

  public userName;
  public dataTrack;
  public orgName;
  public sfOrgMembershipSID='';

  public userDataAdapter;
  public userDataSource;
  public orgListSource;
  public userOrgListSource;
  public adminView = false;
  appPrvilegeObservable: Observable<any>;
  public userLogin: LoginUserModel;
  public sfUserSID;
  public sfOrgSID;
  public rizeDataArray = new Array();
  public rizeDataStruct: RizeDataModel;
  public uploadedFiles: Array < File > ;
  public dataTable = false;
  public isMultiOrg = false;
  public fileUploadResponse ='';
  public isAdminUser = true;

  appPrvilegeSubscription: Subscription;
  constructor(private appService: appService,  private cpxStoreService: CPXStoreService,  private router:Router, private httpclient:HttpClient ) {
    wjCore.setLicenseKey('dataforrize.amga.org,737636598142256#B0SiznOiwmbBJye0ICRiwiI34zZwdVWpRVdpV7NmRTNzp4MHlzTGhTMr8ERWJDaGJ7ZKVmcrNDVwZ6MOpFdtRFVYpncwN7Y03WNwtEe7kzYrBVRKh4NClTZuN7dTRkVollc7hUeCNHaWh7SMdldlRUdO5kQGNjS8hWR8MlbO9kdL5ka4wUcl3COSFGNyFjRYpEVldkdzsWcxNEcwJ5Z8NlWp5EZ7ZmTzVlUL3ESolFciVndwUnW6F6dWFnQW9GN4F7dmJHcjpFVIdDTm9ERz8meShXbZJmT0R5NYpkeKdTWxNDOsdHM6pUNB5GdQ9EMLhUUBN5Ukh4LzIEM5JTdsFFdaZ4ZrIHVrUEdZZ5Kn36U7lHSCpVSyVGUQZnevF7d9Q4TLVUMThzSTFFUvhHWwljYPJnc4ZHOaBldjVnawhVcnFlTKl5RK3mZ84GVNVnZhJUOy3CT7kXdIBzVw3GZroXSygEa6dWWNRmI0IyUiwiI7cDREJjNwYjI0ICSiwCMwIzN6IDM6gTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI9IDNxUDMgYjM8ATMyAjMiojI4J7QiwiInJ7buE6ZtFmLlpXayJ7bmFGdhRmI0IyctRkIsISQH5UQiojIh94QiwiI6UjMyQTM8kTN6MjN7MzNiojIklkIs4XXbpjInxmZiwiIyYXMyAjMiojIyVmdiwSZzxWYmpjnyiH');
    this.userLogin = new LoginUserModel();
    this.rizeDataStruct = new RizeDataModel();
    this.appPrvilegeObservable = this.cpxStoreService.getReducer('AppPrivilegesReducer'); 
    //this._getData();
  }
  ngOnInit(){
    this.appPrvilegeSubscription = this.appPrvilegeObservable.subscribe((data) => {
      if ( data != undefined && data && data.userName != '') {
          this.dataTable = true;
          this.userName = data.userName;
          this.isAdminUser = this.userName.includes("amga.org")?true:false; 
          this.sfUserSID = data.sfUserSID;
          this.sfOrgSID = data.sfOrgSID;
          this.sfOrgMembershipSID = data.sfOrgMembershipSID;
          this.dataTrack = data.dataTrack;
          this.orgName = data.orgName;
  
          this.userLogin.userName = this.userName;
          this.userLogin.appSelected = "dataCollect";
          this.userLogin.dataTrack = this.dataTrack;
          this.userLogin.sfOrgMembershipSID = this.sfOrgMembershipSID;
          this.userLogin.orgName = this.orgName;
          if(data.isPrimOrgParent){
            this.orgListSource = data.subOrgs;
            this.isMultiOrg = true;
          }else{
          this.orgListSource =  [{"orgName":this.orgName,"campMemSID":this.sfOrgMembershipSID}];
          }
          this.userOrgListSource = this.orgListSource;
          if(this.sfOrgMembershipSID != ''){
            this. getRIZEDataFromSQLServer();
          }else{
            this._getData();
          }
      }else{
        this.router.navigateByUrl('/');
      }
    });
  
  }
  ngAfterViewInit(){
    //this.dataGrid.refresh();
  }
  getRIZEDataFromSQLServer(){
    if(this.sfOrgMembershipSID != ''){
      this.appService.getRIZEDataForOrganization(this.sfOrgMembershipSID).subscribe((rizeData) =>{
        //alert('On init');
        var rizeDataSet =rizeData['recordsets'][0];
        var i;
        this.rizeDataArray = new Array();
        if(rizeDataSet.length > 0){
        for(i=0;i<rizeDataSet.length;i++){
            this.rizeDataStruct = new RizeDataModel();
            this.rizeDataStruct.REPORTING_QUARTER = rizeDataSet[i]['REPORTING_QUARTER'];
            this.rizeDataStruct.M1_DEN = rizeDataSet[i]['M1_DEN'];
            this.rizeDataStruct.M1_NUM = rizeDataSet[i]['M1_NUM'];
            this.rizeDataStruct.M2_DEN = rizeDataSet[i]['M2_DEN'];
            this.rizeDataStruct.M2_NUM_PART_A = rizeDataSet[i]['M2_NUM_PART_A'];
            this.rizeDataStruct.M2_NUM_PART_B = rizeDataSet[i]['M2_NUM_PART_B'];
            this.rizeDataStruct.M3_DEN = rizeDataSet[i]['M1_DEN'];
            this.rizeDataStruct.M3_NUM_PART_A = rizeDataSet[i]['M3_NUM_PART_A'];
            this.rizeDataStruct.M3_NUM_PART_B = rizeDataSet[i]['M3_NUM_PART_B'];
            this.rizeDataStruct.M4_DEN = rizeDataSet[i]['M4_DEN'];
            this.rizeDataStruct.M4_NUM_PART_A = rizeDataSet[i]['M4_NUM_PART_A'];
            this.rizeDataStruct.M4_NUM_PART_B = rizeDataSet[i]['M4_NUM_PART_B'];
            this.rizeDataStruct.M5_DEN = rizeDataSet[i]['M2_DEN'];
            this.rizeDataStruct.M5_NUM = rizeDataSet[i]['M5_NUM'];
            /*DM-840*/
            this.rizeDataStruct.M6_DEN = rizeDataSet[i]['M6_DEN'];
            this.rizeDataStruct.M6_NUM_PART_A = rizeDataSet[i]['M6_NUM_PART_A'];
            this.rizeDataStruct.M6_NUM_PART_B = rizeDataSet[i]['M6_NUM_PART_B'];
            this.rizeDataStruct.M7_NUM = rizeDataSet[i]['M7_NUM'];
            this.rizeDataStruct.M8_DEN = rizeDataSet[i]['M8_DEN'];
            this.rizeDataStruct.M8_NUM_PART_A = rizeDataSet[i]['M8_NUM_PART_A'];
            this.rizeDataStruct.M8_NUM_PART_B = rizeDataSet[i]['M8_NUM_PART_B'];
            /*DM-840*/
            this.rizeDataStruct.SUBMITTER_EMAIL_ID = rizeDataSet[i]['SUBMITTER_EMAIL_ID'];
            this.rizeDataStruct.RECORD_UPDATE_DT = rizeDataSet[i]['RECORD_UPDATE_DT'];
            this.rizeDataStruct.SUBMISSION_SOURCE = rizeDataSet[i]['SUBMISSION_SOURCE'];
            this.rizeDataArray[i]=this.rizeDataStruct;
        }
        this._getSQLData();
      }else{
        this._getData();
      }
      })
    }else{
      this._getData();
    }
  }
  onInitialized(dataGrid: wjGrid.FlexGrid) {
    // create extra header row
    //var dataGrid = new wjGrid.FlexGrid('#dataGrid')
    //alert('Hi' + dataGrid);
    var extraRow = new wjGrid.Row();
    extraRow.allowMerging = true;
    //
    // add extra header row to the grid
    var panel = dataGrid.columnHeaders;
    panel.rows.splice(0, 0, extraRow);
    //Find Track
    var isCore = this.dataTrack.toUpperCase().localeCompare('CORE TRACK')===0?true:false;
    // populate the extra header row
    for (let colIndex = 4; colIndex <= 5; colIndex++) {
        panel.setCellData(0, colIndex, 'Data Submission');
    }
    for (let colIndex = 6; colIndex <= 8; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 1: Influenza');
    }
    for (let colIndex = 9; colIndex <= 12; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 2: Pneumococcal');
    }
    for (let colIndex = 13; colIndex <= 16; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 3: Td/Tdap');
    } 
    for (let colIndex = 17; colIndex <= 20; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 4: Zoster');
    } 
    for (let colIndex = 21; colIndex <= 23; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 5: Bundle');
    }  
    /*DM-840*/ 
    for (let colIndex = 24; colIndex <= 27; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 6: RSV');
    }
    for (let colIndex = 28; colIndex <= 30; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 7: COVID-19');
    }  
    for (let colIndex = 31; colIndex <= 24; colIndex++) {
      panel.setCellData(0, colIndex, 'Measure 8: Hepatitis B');
    }  
    /*DM-840*/      
    //
    // merge headers vertically
    ['phase', 'repQuarter','activePatient','measurementPrd','SUBMITTER_EMAIL_ID','SUBMISSION_SOURCE','RECORD_UPDATE_DT'].forEach(function (binding) {
        let col = dataGrid.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
    });
    //
    // center-align merged header cells
    dataGrid.formatItem.addHandler(function (s: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
        if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
            var html = e.cell.innerHTML;
            e.cell.innerHTML = '<div class="v-center">' + html + '</div>';
        }
        if(e.panel.cellType==wjGrid.CellType.ColumnHeader){
          if(e.row==1){
            switch(e.panel.columns[e.col].binding){
                  case "phase":
                  case "repQuarter":
                  case "dsOpen":
                  case "dsClose": e.cell.style.setProperty("background","#09727D");e.cell.style.setProperty("color","white");break;
                  case "m1DenData":
                  case "m1NumData":
                  case "m1Calc": e.cell.style.setProperty("background","#DA656D");e.cell.style.setProperty("color","white");break;  
                  case "M2_DEN":
                  case "M2_NUM_PART_A":
                  case "M2_NUM_PART_B": 
                  case "m2Calc": e.cell.style.setProperty("background","#9F92C9");e.cell.style.setProperty("color","white");break;
                  case "M3_DEN":
                  case "M3_NUM_PART_A":
                  case "M3_NUM_PART_B": 
                  case "m3Calc": if(isCore) {e.cell.style.setProperty("background","#008CB5");e.cell.style.setProperty("color","white");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;   
                  case "M4_DEN":
                  case "M4_NUM_PART_A":
                  case "M4_NUM_PART_B": 
                  case "m4Calc": if(isCore) {e.cell.style.setProperty("background","#9BC038");e.cell.style.setProperty("color","white");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;    
                  case "M5_DEN":
                  case "M5_NUM":
                  case "m5Calc": if(isCore) {e.cell.style.setProperty("background","#06555E");e.cell.style.setProperty("color","white");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break; 
                  case "M6_DEN":
                  case "M6_NUM_PART_A":
                  case "M6_NUM_PART_B": 
                  case "m6Calc": e.cell.style.setProperty("background","#C50D7B");e.cell.style.setProperty("color","white");break;  
                  case "M7_DEN":
                  case "M7_NUM":
                  case "m7Calc": e.cell.style.setProperty("background","#8A7950");e.cell.style.setProperty("color","white");break;  
                  case "M8_DEN":
                  case "M8_NUM_PART_A":
                  case "M8_NUM_PART_B": 
                  case "m8Calc": e.cell.style.setProperty("background","#EE8C2A");e.cell.style.setProperty("color","white");break;                                                       
                  default: e.cell.style.setProperty("background","#eaeaea");break;
              }
            }
            else if(e.row==0){
              //alert('Binding-->'+e.panel.columns[e.col].binding);
                switch(e.panel.columns[e.col].binding){
                      case "phase":
                      case "repQuarter":
                      case "dsOpen":
                      case "dsClose": e.cell.style.setProperty("background","#09727D");e.cell.style.setProperty("color","white");break;
                      case "m1DenData": e.cell.style.setProperty("background","#DA656D");e.cell.style.setProperty("color","white");break;                    
                      case "M2_DEN": e.cell.style.setProperty("background","#9F92C9");e.cell.style.setProperty("color","white");break;  
                      case "M3_DEN": if(isCore) {e.cell.style.setProperty("background","#008CB5");e.cell.style.setProperty("color","white");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;  
                      case "M4_DEN": if(isCore) {e.cell.style.setProperty("background","#9BC038");e.cell.style.setProperty("color","white");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break; 
                      case "M5_DEN": if(isCore) {e.cell.style.setProperty("background","#06555E");e.cell.style.setProperty("color","white");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;
                      case "M6_DEN": e.cell.style.setProperty("background","#C50D7B");e.cell.style.setProperty("color","white");break;                    
                      case "M7_DEN": e.cell.style.setProperty("background","#8A7950");e.cell.style.setProperty("color","white");break; 
                      case "M8_DEN": e.cell.style.setProperty("background","#EE8C2A");e.cell.style.setProperty("color","white");break; 
                      case "SUBMITTER_EMAIL_ID":
                      case "RECORD_UPDATE_DT":
                      case "SUBMISSION_SOURCE": e.cell.style.setProperty("background","#00B000");e.cell.style.setProperty("color","white");break;
                      default: e.cell.style.setProperty("background","#eaeaea");break;
            }
            }
        }
        if(e.panel.cellType==wjGrid.CellType.Cell){
            switch(e.panel.columns[e.col].binding){
              case "m1DenData":
              case "m1NumData": e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");break; 
              case "m1Calc": e.cell.style.setProperty("color","#09727D");break;  
              case "M2_DEN":
              case "M2_NUM_PART_A":
              case "M2_NUM_PART_B": e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");break;
              case "m2Calc": e.cell.style.setProperty("color","#09727D");break;
              case "M3_DEN": if(isCore) {e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;
              case "M3_NUM_PART_A":
              case "M3_NUM_PART_B": if(isCore) {e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break; 
              case "m3Calc": if(isCore) {e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;   
              case "M4_DEN":
              case "M4_NUM_PART_A":
              case "M4_NUM_PART_B": if(isCore) {e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;
              case "m4Calc":  
              case "M5_DEN": if(isCore) {e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;
              case "M5_NUM": if(isCore) {e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break; 
              case "m5Calc": if(isCore) {e.cell.style.setProperty("color","#09727D");}else{e.cell.style.setProperty("background","#EAEAEA");e.cell.style.setProperty("color","#EAEAEA")}break;                                                         
              case "M6_DEN":
              case "M6_NUM_PART_A":
              case "M6_NUM_PART_B": e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");break;
              case "m6Calc": e.cell.style.setProperty("color","#09727D");break;
              case "M7_DEN": e.cell.style.setProperty("color","#09727D");break;
              case "M7_NUM": e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");break;
              case "m7Calc": e.cell.style.setProperty("color","#09727D");break;
              case "M8_DEN":
              case "M8_NUM_PART_A":
              case "M8_NUM_PART_B": e.cell.style.setProperty("background","#D1F5FF");e.cell.style.setProperty("color","#09727D");break;
              case "m8Calc": e.cell.style.setProperty("color","#09727D");break;
              default: e.cell.style.setProperty("background","#eaeaea");break;
          }
        }
    });
}

private _getData() {
  var phase = 'Pre-Covid,Inter-Covid,2021,2022,2023,2024'.split(',');
  var repQtr = 'Q3_2019,Q4_2019,Q1_2020,Q2_2020,Q3_2020,Q4_2020,Q1_2021,Q2_2021,Q3_2021,Q4_2021,Q1_2022,Q2_2022,Q3_2022,Q4_2022,Q1_2023,Q2_2023,Q3_2023,Q4_2023,Q1_2024,Q2_2024,Q3_2024,Q4_2024,Q1_2025,Q2_2025,Q3_2025,Q4_2025,Q1_2026,Q2_2026,Q3_2026,Q4_2026,Q1_2027,Q2_2027'.split(',');
  var actPat = '4/1/2018–9/30/2019,4/1/2018–12/31/2019,4/1/2018–3/31/2020,4/1/2018–6/30/2020,4/1/2019–9/30/2020,4/1/2019–12/31/2020,4/1/2019–3/31/2021,4/1/2019–6/30/2021,4/1/2020–9/30/2021,4/1/2020–12/31/2021,4/1/2020–3/31/2022,4/1/2020–6/30/2022,4/1/2021–9/30/2022,4/1/2021–12/31/2022,4/1/2021–3/31/2023,4/1/2021–6/30/2023,4/1/2022–9/30/2023,4/1/2022–12/31/2023,4/1/2022–3/31/2024,4/1/2022–6/30/2024,4/1/2023–9/30/2024,4/1/2023–12/31/2024,4/1/2023–3/31/2025,4/1/2023–6/30/2025,4/1/2024–9/30/2025,4/1/2024–12/31/2025,4/1/2024–3/31/2026,4/1/2024–6/30/2026,4/1/2025–9/30/2026,4/1/2025–12/31/2026,4/1/2025–3/31/2027,4/1/2025–6/30/2027'.split(',');
  var msmtPeriod = '7/1/2019–9/30/2019,7/1/2019–12/31/2019,7/1/2019–3/31/2020,7/1/2019–6/30/2020,7/1/2020–9/30/2020,7/1/2020–12/31/2020,7/1/2020–3/31/2021,7/1/2020–6/30/2021,7/1/2021–9/30/2021,7/1/2021–12/31/2021,7/1/2021–3/31/2022,7/1/2021–6/30/2022,7/1/2022–9/30/2022,7/1/2022–12/31/2022,7/1/2022–3/31/2023,7/1/2022–6/30/2023,7/1/2023–9/30/2023,7/1/2023–12/31/2023,7/1/2023–3/31/2024,7/1/2023–6/30/2024,7/1/2024–9/30/2024,7/1/2024–12/31/2024,7/1/2024–3/31/2025,7/1/2024–6/30/2025,7/1/2025–9/30/2025,7/1/2025–12/31/2025,7/1/2025–3/31/2026,7/1/2025–6/30/2026,7/1/2026–9/30/2026,7/1/2026–12/31/2026,7/1/2026–12/31/2027,7/1/2026–12/31/2027'.split(',');
  var dSubOpen = '2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,4/1/2022,7/1/2022,10/1/2022,1/2/2023,4/1/2023,7/1/2023,10/1/2023,1/2/2024,4/1/2024,7/1/2024,10/1/2024,1/2/2025,4/1/2025,7/1/2025,10/1/2025,1/2/2026,4/1/2026,7/1/2026,10/1/2026,1/2/2027,4/1/2027,7/1/2027'.split(',');
  var dSubClose = '2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,4/15/2022,7/15/2022,10/14/2022,1/17/2023,4/14/2023,7/14/2023,10/16/2023,1/16/2024,4/15/2024,7/15/2024,10/15/2024,1/15/2025,4/15/2025,7/15/2025,10/15/2025,1/15/2026,4/15/2026,7/15/2026,10/15/2026,1/15/2027,4/15/2027,7/15/2027'.split(',');
   var emptyData = ',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,'.split(',');
  var data = [];
  for (var i = 0; i < 32; i++) {
    var currentPhase = repQtr[i].includes("2019")||repQtr[i].includes("Q1_2020")||repQtr[i].includes("Q2_2020")? "Pre-Covid": repQtr[i].includes("Q3_2020")||repQtr[i].includes("Q4_2020")||repQtr[i].includes("Q1_2021")||repQtr[i].includes("Q2_2021")?"Intra Covid":repQtr[i].includes("Q3_2021")||repQtr[i].includes("Q4_2021")||repQtr[i].includes("Q1_2022")||repQtr[i].includes("Q2_2022")?"2021":repQtr[i].includes("Q3_2022")||repQtr[i].includes("Q4_2022")||repQtr[i].includes("Q1_2023")||repQtr[i].includes("Q2_2023")?"2022":repQtr[i].includes("Q3_2023")||repQtr[i].includes("Q4_2023")||repQtr[i].includes("Q1_2024")||repQtr[i].includes("Q2_2024")?"2023":repQtr[i].includes("Q3_2024")||repQtr[i].includes("Q4_2024")||repQtr[i].includes("Q1_2025")||repQtr[i].includes("Q2_2025")?"2024":repQtr[i].includes("Q3_2025")||repQtr[i].includes("Q4_2025")||repQtr[i].includes("Q1_2026")||repQtr[i].includes("Q2_2026")?"2025":"2026";
      data.push({
          id: i,
          phase: currentPhase,
          repQuarter: repQtr[i],
          activePatient: actPat[i],
          measurementPrd: msmtPeriod[i],
          dsOpen: dSubOpen[i],
          dsClose: dSubClose[i],
          emptyDt: emptyData[i]
      });

  }
  this.wjData = new wjCore.CollectionView(data, {
  });
  if(this.dataGrid != undefined && this.dataGrid.itemsSource != undefined){
    this.dataGrid.itemsSource = this.wjData;
  }
}
private _getSQLData(){
  var phase = 'Pre-Covid,Inter-Covid,2021,2022,2023,2024,2025,2026'.split(',');
  var repQtr = 'Q3_2019,Q4_2019,Q1_2020,Q2_2020,Q3_2020,Q4_2020,Q1_2021,Q2_2021,Q3_2021,Q4_2021,Q1_2022,Q2_2022,Q3_2022,Q4_2022,Q1_2023,Q2_2023,Q3_2023,Q4_2023,Q1_2024,Q2_2024,Q3_2024,Q4_2024,Q1_2025,Q2_2025,Q3_2025,Q4_2025,Q1_2026,Q2_2026,Q3_2026,Q4_2026,Q1_2027,Q2_2027'.split(',');
  var actPat = '4/1/2018–9/30/2019,4/1/2018–12/31/2019,4/1/2018–3/31/2020,4/1/2018–6/30/2020,4/1/2019–9/30/2020,4/1/2019–12/31/2020,4/1/2019–3/31/2021,4/1/2019–6/30/2021,4/1/2020–9/30/2021,4/1/2020–12/31/2021,4/1/2020–3/31/2022,4/1/2020–6/30/2022,4/1/2021–9/30/2022,4/1/2021–12/31/2022,4/1/2021–3/31/2023,4/1/2021–6/30/2023,4/1/2022–9/30/2023,4/1/2022–12/31/2023,4/1/2022–3/31/2024,4/1/2022–6/30/2024,4/1/2023–9/30/2024,4/1/2023–12/31/2024,4/1/2023–3/31/2025,4/1/2023–6/30/2025,4/1/2024–9/30/2025,4/1/2024–12/31/2025,4/1/2024–3/31/2026,4/1/2024–6/30/2026,4/1/2025–9/30/2026,4/1/2025–12/31/2026,4/1/2025–3/31/2027,4/1/2025–6/30/2027'.split(',');
  var msmtPeriod = '7/1/2019–9/30/2019,7/1/2019–12/31/2019,7/1/2019–3/31/2020,7/1/2019–6/30/2020,7/1/2020–9/30/2020,7/1/2020–12/31/2020,7/1/2020–3/31/2021,7/1/2020–6/30/2021,7/1/2021–9/30/2021,7/1/2021–12/31/2021,7/1/2021–3/31/2022,7/1/2021–6/30/2022,7/1/2022–9/30/2022,7/1/2022–12/31/2022,7/1/2022–3/31/2023,7/1/2022–6/30/2023,7/1/2023–9/30/2023,7/1/2023–12/31/2023,7/1/2023–3/31/2024,7/1/2023–6/30/2024,7/1/2024–9/30/2024,7/1/2024–12/31/2024,7/1/2024–3/31/2025,7/1/2024–6/30/2025,7/1/2025–9/30/2025,7/1/2025–12/31/2025,7/1/2025–3/31/2026,7/1/2025–6/30/2026,7/1/2026–9/30/2026,7/1/2026–12/31/2026,7/1/2026–12/31/2027,7/1/2026–12/31/2027'.split(',');
  var dSubOpen = '2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,2/1/2022,4/1/2022,7/1/2022,10/1/2022,1/2/2023,4/1/2023,7/1/2023,10/1/2023,1/2/2024,4/1/2024,7/1/2024,10/1/2024,1/2/2025,4/1/2025,7/1/2025,10/1/2025,1/2/2026,4/1/2026,7/1/2026,10/1/2026,1/2/2027,4/1/2027,7/1/2027'.split(',');
  var dSubClose = '2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,2/15/2022,4/15/2022,7/15/2022,10/14/2022,1/17/2023,4/14/2023,7/14/2023,10/16/2023,1/16/2024,4/15/2024,7/15/2024,10/15/2024,1/15/2025,4/15/2025,7/15/2025,10/15/2025,1/15/2026,4/15/2026,7/15/2026,10/15/2026,1/15/2027,4/15/2027,7/15/2027'.split(',');
   var emptyData = ',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,'.split(',');
  var data = [];
  /*When data is skipped in the middle we need separate variable to track that*/
  var dataIdx = 0;
  for (var i = 0; i < 32; i++) {
    var currentPhase = repQtr[i].includes("2019")||repQtr[i].includes("Q1_2020")||repQtr[i].includes("Q2_2020")? "Pre-Covid": repQtr[i].includes("Q3_2020")||repQtr[i].includes("Q4_2020")||repQtr[i].includes("Q1_2021")||repQtr[i].includes("Q2_2021")?"Intra Covid":repQtr[i].includes("Q3_2021")||repQtr[i].includes("Q4_2021")||repQtr[i].includes("Q1_2022")||repQtr[i].includes("Q2_2022")?"2021":repQtr[i].includes("Q3_2022")||repQtr[i].includes("Q4_2022")||repQtr[i].includes("Q1_2023")||repQtr[i].includes("Q2_2023")?"2022":repQtr[i].includes("Q3_2023")||repQtr[i].includes("Q4_2023")||repQtr[i].includes("Q1_2024")||repQtr[i].includes("Q2_2024")?"2023":repQtr[i].includes("Q3_2024")||repQtr[i].includes("Q4_2024")||repQtr[i].includes("Q1_2025")||repQtr[i].includes("Q2_2025")?"2024":repQtr[i].includes("Q3_2025")||repQtr[i].includes("Q4_2025")||repQtr[i].includes("Q1_2026")||repQtr[i].includes("Q2_2026")?"2025":"2026";
    var REPT_QTR_MATCH = this.rizeDataArray[dataIdx] != undefined && this.rizeDataArray[dataIdx].REPORTING_QUARTER.replace(' ','_').localeCompare(repQtr[i]) == 0?true:false;
    var m1DenData = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M1_DEN:'';
    var m1NumData = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M1_NUM:'';
    var M2_DEN = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_DEN:'',
    M2_NUM_PART_A = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_NUM_PART_A:'',
    M2_NUM_PART_B = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_NUM_PART_B:'',
    M3_DEN = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_DEN:'',
    M3_NUM_PART_A = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_NUM_PART_A:'',
    M3_NUM_PART_B = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_NUM_PART_B:'', 
    M4_DEN = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_DEN:'',
    M4_NUM_PART_A = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_NUM_PART_A:'',
    M4_NUM_PART_B = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_NUM_PART_B:'',  
    M5_DEN = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M5_DEN:'',  
    M5_NUM = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M5_NUM:'',
    M6_DEN = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M6_DEN:'',
    M6_NUM_PART_A = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M6_NUM_PART_A:'',
    M6_NUM_PART_B = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M6_NUM_PART_B:'',
    M7_NUM = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M7_NUM:'',
    M8_DEN = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M8_DEN:'',
    M8_NUM_PART_A = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M8_NUM_PART_A:'',
    M8_NUM_PART_B = REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M8_NUM_PART_B:'';
    
    if(i > 15){
      data.push({
          id: i,
          phase: currentPhase,
          repQuarter: repQtr[i],
          activePatient: actPat[i],
          measurementPrd: msmtPeriod[i],
          dsOpen: dSubOpen[i],
          dsClose: dSubClose[i],
          m1DenData: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M1_DEN:'',
          m1NumData: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M1_NUM:'',
          m1Calc: m1DenData !='' && m1NumData !=''? Math.round(m1NumData/m1DenData*100)+'%':'', 
          M2_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_DEN:'',
          M2_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_NUM_PART_A:'',
          M2_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_NUM_PART_B:'',
          m2Calc: M2_DEN !='' && M2_NUM_PART_A !='' && M2_NUM_PART_B !=''? Math.round((M2_NUM_PART_A+M2_NUM_PART_B)/M2_DEN*100)+'%':'', 
          M3_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_DEN:'',
          M3_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_NUM_PART_A:'',
          M3_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_NUM_PART_B:'',   
          m3Calc: m1DenData !='' && M3_NUM_PART_A !='' && M3_NUM_PART_B !=''? Math.round((M3_NUM_PART_A+M3_NUM_PART_B)/M3_DEN*100)+'%':'',  
          M4_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_DEN:'',
          M4_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_NUM_PART_A:'',
          M4_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_NUM_PART_B:'',
          m4Calc: M4_DEN !='' && M4_NUM_PART_A !='' && M4_NUM_PART_B !=''? Math.round((M4_NUM_PART_A+M4_NUM_PART_B)/M4_DEN*100)+'%':'',  
          M5_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_DEN:'',    
          M5_NUM: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M5_NUM:'',    
          m5Calc: M2_DEN !='' && M5_NUM !='' ? Math.round(M5_NUM/M5_DEN*100)+'%':'',
          M6_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M6_DEN:'',
          M6_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M6_NUM_PART_A:'',
          M6_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M6_NUM_PART_B:'',
          m6Calc: M6_DEN !='' && M6_DEN !=null && M6_NUM_PART_A !='' && M6_NUM_PART_B !=''? Math.round((M6_NUM_PART_A+M6_NUM_PART_B)/M6_DEN*100)+'%':'',
          M7_NUM: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M7_NUM:'',
          M7_DEN: REPT_QTR_MATCH && M7_NUM != null?this.rizeDataArray[dataIdx].M1_DEN:'',
          m7Calc: m1DenData !='' && M7_NUM !=null && M7_NUM !='' ? Math.round(M7_NUM/m1DenData*100)+'%':'',
          M8_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M8_DEN:'',
          M8_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M8_NUM_PART_A:'',
          M8_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M8_NUM_PART_B:'',
          m8Calc: M8_DEN !='' && M8_DEN !=null && M8_NUM_PART_A !='' && M8_NUM_PART_B !=''? Math.round((M8_NUM_PART_A+M8_NUM_PART_B)/M8_DEN*100)+'%':'',  
          SUBMITTER_EMAIL_ID: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].SUBMITTER_EMAIL_ID:'',
          RECORD_UPDATE_DT: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].RECORD_UPDATE_DT:'',
          SUBMISSION_SOURCE: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].SUBMISSION_SOURCE:'', 
          emptyDt: emptyData[i]
      });
      }else{
        data.push({
          id: i,
          phase: currentPhase,
          repQuarter: repQtr[i],
          activePatient: actPat[i],
          measurementPrd: msmtPeriod[i],
          dsOpen: dSubOpen[i],
          dsClose: dSubClose[i],
          m1DenData: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M1_DEN:'',
          m1NumData: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M1_NUM:'',
          m1Calc: m1DenData !='' && m1NumData !=''? Math.round(m1NumData/m1DenData*100)+'%':'', 
          M2_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_DEN:'',
          M2_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_NUM_PART_A:'',
          M2_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_NUM_PART_B:'',
          m2Calc: M2_DEN !='' && M2_NUM_PART_A !='' && M2_NUM_PART_B !=''? Math.round((M2_NUM_PART_A+M2_NUM_PART_B)/M2_DEN*100)+'%':'', 
          M3_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_DEN:'',
          M3_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_NUM_PART_A:'',
          M3_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M3_NUM_PART_B:'',   
          m3Calc: m1DenData !='' && M3_NUM_PART_A !='' && M3_NUM_PART_B !=''? Math.round((M3_NUM_PART_A+M3_NUM_PART_B)/M3_DEN*100)+'%':'',  
          M4_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_DEN:'',
          M4_NUM_PART_A: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_NUM_PART_A:'',
          M4_NUM_PART_B: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M4_NUM_PART_B:'',
          m4Calc: M4_DEN !='' && M4_NUM_PART_A !='' && M4_NUM_PART_B !=''? Math.round((M4_NUM_PART_A+M4_NUM_PART_B)/M4_DEN*100)+'%':'',  
          M5_DEN: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M2_DEN:'',    
          M5_NUM: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].M5_NUM:'',    
          m5Calc: M2_DEN !='' && M5_NUM !='' ? Math.round(M5_NUM/M5_DEN*100)+'%':'',
          SUBMITTER_EMAIL_ID: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].SUBMITTER_EMAIL_ID:'',
          RECORD_UPDATE_DT: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].RECORD_UPDATE_DT:'',
          SUBMISSION_SOURCE: REPT_QTR_MATCH ?this.rizeDataArray[dataIdx].SUBMISSION_SOURCE:'', 
          emptyDt: emptyData[i]
        });
      }
      if(this.rizeDataArray[dataIdx] != undefined && REPT_QTR_MATCH === true){
        /*Increase Data index only when data is not skipped in between*/
        dataIdx = dataIdx + 1;
    }
  }
  this.wjData = new wjCore.CollectionView(data, {
  });
    this.dataGrid.itemsSource = this.wjData;
}

  
  userMgmtColumns: any[] =
  [
      { text: 'Address', datafield: 'Address',  width:'40%',editable:false },
  ];
  export(): void {
    /* generate worksheet */
    this.appService.getRizeDataSubmissionEmails().subscribe((adLogin) => {
      //alert('Emails read!!!');
    })
    //const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    //const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
  }
  fileChange(element) {
    this.uploadedFiles = element.target.files;
  }
  
  upload() {
  this.jqxLoader.open();
  this.fileUploadResponse = "Upload in progress...";
  let formData = new FormData();
  for (var i = 0; i < this.uploadedFiles.length; i++) {
      var data = 'userID:'+this.sfUserSID+':PrimeAffID:'+this.sfOrgSID+':orgName:'+this.orgName+':collMemID:'+this.sfOrgMembershipSID+':track:'+this.dataTrack+':isMultiOrg:'+this.isMultiOrg+':userEmail:'+this.userName+':origFileName:'+this.uploadedFiles[i].name
      formData.append('file', this.uploadedFiles[i], data);
      formData.append("file", data);
  }
  this.httpclient.post('/api/upload', formData)
      .subscribe((response) => {
          console.log('response received is ', response);
          this.fileInput.nativeElement.value = "";
          this.fileUploadResponse = response['message'];
          this. getRIZEDataFromSQLServer();
          this.jqxLoader.close();
      })
}

handle($event){
  alert($event);
}
orgChange(event: any): void {
  let args = event.args;
  let item = this.orgList.getItem(args.index);
  if (item != null) {
    this.sfOrgMembershipSID = item.value;
    for(var i = 0; i < this.orgListSource.length; i++) {
      var obj = this.orgListSource[i];
      if(obj.campMemSID === this.sfOrgMembershipSID){
      this.sfOrgSID = obj.orgSID;
      this.orgName = obj.orgName;
    }
  }
    //alert(this.sfOrgMembershipSID + '-- '+this.sfOrgSID + '--'+this.orgName);
      this. getRIZEDataFromSQLServer();
  }
};
adminFunctionSelected(event: any){
  if(event.args.checked){
  this.jqxLoader.open();
  this.appService.getAllParticipatingOrgs().subscribe((orgList) => {
    var responseMesg = orgList['response_message'];
    var result = orgList['response_body'];
    this.orgListSource = result.orgs;
    this.orgList.selectIndex(0);
    this.adminView = true;
    this.jqxLoader.close();
  })}else{
    this.orgListSource = this.userOrgListSource;
    this.orgList.selectIndex(0);
    this.adminView = false;
  }
}

}