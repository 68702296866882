import { ActionReducer, MetaReducer} from '@ngrx/store';

import {LoginPayload,cpxPayload, ReportSelectedPayload, PasswordResetPayload, SearchInfoPayload, DocumentInfoPayload} from './cpxStateModel';

export const CLEAR = 'CLEAR';
export const LoggedInUser = 'LoggedInUser';
export const ReportSelected = 'ReportSelected';
export const ServiceList = 'ServiceList';
export const AppPrivileges = 'AppPrivileges';
export const AppSecurityRoleList = 'AppSecurityRoleList';
export const SelectedModule = 'SelectedModule';
export const PasswordResetRequest = 'PasswordResetRequest';
export const ReportSearchSetting = 'ReportSearchSetting';
export const ReportViewSearchSetting = 'ReportViewSearchSetting';
export const DocumentInfo = 'DocumentInfo';


export interface Action {
    type: any;
    payload?: any;
}

// Login User Data
export class LoginStore implements Action {
    readonly type;
    constructor(public payload: LoginPayload) {}

}

export class AppPrivilegesStore implements Action {
    readonly type;
    constructor(public payload: cpxPayload) {}

}
export class ReportSelectedStore implements Action {
    readonly type;
    constructor(public payload: ReportSelectedPayload) {}

}
export class PasswordResetStore implements Action {
    readonly type;
    constructor(public payload: PasswordResetPayload) {}

}
export class SearchInfotStore implements Action {
    readonly type;
    constructor(public payload: SearchInfoPayload) {}

}
export class SearchViewInfotStore implements Action {
    readonly type;
    constructor(public payload: SearchInfoPayload) {}

}

export class DcoumentInfotStore implements Action {
    readonly type;
    constructor(public payload: DocumentInfoPayload) {}

}
 // Login Reducer
export function LoggedInUserReducer(state: any, action: LoginStore) {
    switch (action.type) {
        case LoggedInUser:
            return state = action.payload.user;
            default: return state;
    }

}
export function AppPrivilegesReducer(state: any, action: AppPrivilegesStore) {
    switch (action.type) {
        case LoggedInUser:
            return state = action.payload.appPrivileges;
            default: return state;
    }

}
export function ReportSelectedReducer(state: any, action: ReportSelectedStore) {
    switch (action.type) {
        case ReportSelected:
            return state = action.payload.report;
            default: return state;
    }

}
export function SearchInfoReducer(state: any, action: SearchInfotStore) {
    switch (action.type) {
        case ReportSearchSetting:
            return state = action.payload.searchParams;
            default: return state;
    }

}
export function SearchViewInfoReducer(state: any, action: SearchInfotStore) {
    switch (action.type) {
        case ReportViewSearchSetting:
            return state = action.payload.searchParams;
            default: return state;
    }

}
export function PasswordChangeReducer(state: any, action: PasswordResetStore) {
    switch (action.type) {
        case PasswordResetRequest:
            return state = action.payload.passwordReset;
            default: return state;
    }

}
export function DocumentSelectedReducer(state: any, action: DcoumentInfotStore) {
    switch (action.type) {
        case DocumentInfo:
            return state = action.payload.document;
            default: return state;
    }

}

export function LogoutReducer(state: any, action: DcoumentInfotStore) {
    switch (action.type) {
        case CLEAR:
            return state = undefined;
            default: return state;
    }

}
// clear store meta reducer
export function reset(reducer: ActionReducer<any>): ActionReducer<any> {

    return function(state: any, action: any) {

        if(action.type == CLEAR) {
            state = undefined;
        }
        return reducer(state, action);
      };

}

export const metaReducers: MetaReducer<any>[] = [reset];
